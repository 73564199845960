@font-face {
font-family: 'cannonFont';
src: url(/_next/static/media/46143839ac98a13e-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}@font-face {font-family: 'cannonFont Fallback';src: local("Arial");ascent-override: 81.83%;descent-override: 21.76%;line-gap-override: 17.41%;size-adjust: 114.88%
}.__className_f7df1e {font-family: 'cannonFont', 'cannonFont Fallback';font-weight: 400;font-style: normal
}

@font-face {
font-family: 'ttCommonsProFont';
src: url(/_next/static/media/6049a8358bd83da9-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'ttCommonsProFont';
src: url(/_next/static/media/d659cde1643ff596-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: normal;
}

@font-face {
font-family: 'ttCommonsProFont';
src: url(/_next/static/media/0634378b88fba174-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'ttCommonsProFont Fallback';src: local("Arial");ascent-override: 104.33%;descent-override: 26.08%;line-gap-override: 0.00%;size-adjust: 95.85%
}.__className_b17b89 {font-family: 'ttCommonsProFont', 'ttCommonsProFont Fallback'
}

